import React, { useEffect, useState } from "react";
import { ContactFormTitle } from "@/data";

const ContactForm = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const { subTitle, title, description } = ContactFormTitle;

  const wipeForm = (e) => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setMessage('')
    setPhone('')
  }

  const handleSubmit = (e) => {
   e.preventDefault();

    const contact = { firstName, lastName, email, phone, message }

    const response = fetch('https://mail.custarddigital.co.uk/send', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      //mode: 'cors',// remove later
      body: JSON.stringify(contact)
    }).then((contact) => {
      console.log(response)
      console.log(contact)
    })
    wipeForm()      
  }  

  return (
    <section className="commonSection ContactPage">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h4 className="sub_title">{subTitle}</h4>
            <h2 className="sec_title">{title}</h2>
            <p className="sec_desc">{description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
            <form
              onSubmit={handleSubmit}
              method="post"
              className="contactFrom"
              id="contactForm"
            >
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form required"
                    type="text"
                    name="f_name"
                    id="f_name"
                    placeholder="First Name"
                    value={firstName}
                    onChange={event => setFirstName(event.target.value)}
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form required"
                    type="text"
                    name="l_name"
                    id="l_name"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={event => setLastName(event.target.value)}
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form required"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number (optional)"
                    value={phone}
                    onChange={event => setPhone(event.target.value)}
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <textarea
                    className="input-form required"
                    name="con_message"
                    id="con_message"
                    placeholder="How can we help you?"
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                  ></textarea>
                </div>
              </div>
              <button
                className="common_btn red_bg"
                type="submit"
                id="con_submit"
              >
                <span>Send Message</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
